function isExactActiveLink(pathname, link) {
  return link.identifiers
    ? link.identifiers.some((x) => pathname === x)
    : pathname === link.to;
}

function isActiveLink(pathname, link) {
  return link.identifiers
    ? link.identifiers.some((x) => pathname.startsWith(x))
    : pathname.startsWith(link.to);
}

function checkLink(pathname, links, itemId, subItemId = "", isExact) {
  if (links) {
    for (const link of links) {
      const isActive = isExact
        ? isExactActiveLink(pathname, link)
        : isActiveLink(pathname, link);
      if (isActive) {
        return { display: itemId, subDisplay: subItemId };
      }
    }
  }
  return null;
}

function findMatchingLink(pathname, navigationItems, isExact) {
  for (const item of navigationItems) {
    if (item.subItems) {
      for (const subItem of item.subItems) {
        const result = checkLink(
          pathname,
          subItem.links,
          item.id,
          subItem.id,
          isExact
        );
        if (result) {
          return result;
        }
      }
    }
    const result = checkLink(
      pathname,
      item.links,
      item.id,
      null,
      isExact
    );
    if (result) {
      return result;
    }
  }
  return null;
}

function getCurrentPage(navigationItems) {
  const { pathname } = window.location;

  const exactResult = findMatchingLink(
    pathname,
    navigationItems,
    true
  );
  if (exactResult) {
    return exactResult;
  }

  const partialResult = findMatchingLink(
    pathname,
    navigationItems,
    false
  );
  if (partialResult) {
    return partialResult;
  }

  return { display: "", subDisplay: "" };
}

export { getCurrentPage };
