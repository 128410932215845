import { config } from "@fortawesome/fontawesome-svg-core";
import {
  faAnglesLeft,
  faAnglesRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false;

export {
  faAnglesLeft as AnglesLeft,
  faAnglesRight as AnglesRight,
  faChevronDown as ChevronDown,
  faChevronUp as ChevronUp,
};
