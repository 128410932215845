import axios from "axios";
import { GlobalDispatchMethods } from "./enums";

const API = process.env.REACT_APP_API || "api";

export function Get(dispatch, method) {
  return axios
    .get(API + "/" + method)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);

      if (dispatch) {
        dispatch({
          type: GlobalDispatchMethods.AddNotification,
          notification: { message: error.message, success: false },
          dispatch: dispatch,
        });
      }

      return error;
    });
}

export function GetInitialState(dispatch) {
  return Get(dispatch, "GetInitialState");
}
