import PageState from "../components/PageState";
import SideBar from "../components/sidebar/SideBar";
import Header from "../components/header/Header";
import Notification from "../components/notification/Notification";
import "./style.scss";

function DefaultLayout({ children, renderSidebar = true }) {
  return (
    <>
      <PageState />
      <Header />
      <main>
        {renderSidebar && <SideBar />}
        <div id="content">{children}</div>
        <Notification />
      </main>
    </>
  );
}

export default DefaultLayout;
