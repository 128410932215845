import { getNavigationItems } from "../../js/navigationItems";
import NavigationItem from "./NavigationItem";
import NavigationLink from "./NavigationLink";

function Navigation({
  display,
  subDisplay,
  toggleDisplay,
  toggleSubDisplay,
}) {
  return (
    <div id="nav-wrapper">
      <ul className="nav flex-column mb-3">
        {getNavigationItems().map((item, index) => {
          const { id, links, subItems, text } = item;
          return (
            <NavigationItem
              className={"parentItem"}
              key={index}
              id={id}
              onClick={() => toggleDisplay(id)}
              show={display === id}
              text={text}
            >
              {links.map((link, linkIndex) => (
                <NavigationLink
                  key={linkIndex}
                  to={link.to}
                  text={link.text}
                />
              ))}
              {subItems.map((subItem, subItemIndex) => (
                <NavigationItem
                  className={"subItem"}
                  key={subItemIndex}
                  id={subItem.id}
                  onClick={() => toggleSubDisplay(subItem.id)}
                  show={subDisplay === subItem.id}
                  text={subItem.text}
                >
                  {subItem.links.map((link, linkIndex) => (
                    <NavigationLink
                      key={linkIndex}
                      to={link.to}
                      text={link.text}
                    />
                  ))}
                </NavigationItem>
              ))}
            </NavigationItem>
          );
        })}
      </ul>
    </div>
  );
}

export default Navigation;
