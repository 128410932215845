const AlertStyle = Object.freeze({
  Primary: "alert-primary",
  Secondary: "alert-secondary",
  Success: "alert-success",
  Danger: "alert-danger",
  Warning: "alert-warning",
  Info: "alert-info",
  Light: "alert-light",
  Dark: "alert-dark",
});

const BackgroundStyle = Object.freeze({
  Primary: "bg-primary",
  Secondary: "bg-secondary",
  Success: "bg-success",
  Danger: "bg-danger",
  Warning: "bg-warning",
  Info: "bg-info",
  Light: "bg-light",
  Dark: "bg-dark",
});

const ButtonStyle = Object.freeze({
  Primary: "btn-primary",
  Secondary: "btn-secondary",
  Success: "btn-success",
  Danger: "btn-danger",
  Warning: "btn-warning",
  Info: "btn-info",
  Light: "btn-light",
  Dark: "btn-dark",
  Link: "btn-link",
  OutlinePrimary: "btn-outline-primary",
  OutlineSecondary: "btn-outline-secondary",
  Close: "btn-close",
});

const GlobalDispatchMethods = Object.freeze({
  AddNotification: "Add_Notification",
  RemoveNotification: "Remove_Notification",
  ResetInitialState: "Reset_Initial_State",
  SetInitialState: "Set_Initial_State",
});

const LoadingState = Object.freeze({
  NotLoaded: "NotLoaded",
  Loading: "Loading",
  Loaded: "Loaded",
});

export {
  AlertStyle,
  BackgroundStyle,
  ButtonStyle,
  GlobalDispatchMethods,
  LoadingState,
};
