function Home() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 w-100">
      <img
        src={`${process.env.PUBLIC_URL}/base_logo_transparent_background.svg`}
        alt="VisiT"
        className="mb-5"
      />
      <h1 className="mb-5">Admin</h1>
      <a href="/login" className="btn btn-primary">
        Login
      </a>
    </div>
  );
}
export default Home;
