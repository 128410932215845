import React, { createContext, useContext, useReducer } from "react";
import { globalReducer } from "./GlobalReducer";

export const GlobalContext = createContext();

export function useGlobalState() {
  return useContext(GlobalContext)[0];
}

export function useGlobalReducer() {
  return useContext(GlobalContext)[1];
}

export function GlobalProvider({ children }) {
  let initialState = {
    notifications: [],
    notificationId: 0,
    initialStateBound: false,
    user: null,
    config: [],
  };

  const value = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
}
