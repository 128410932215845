const navigationItems = [
  {
    id: "emailMarketing",
    text: "Email Marketing",
    links: [
      {
        identifiers: ["/communications", "/communication"],
        to: "/communications",
        text: "Communications",
      },
    ],
    subItems: [],
  },
];

function getNavigationItems() {
  return navigationItems.reduce((result, item) => {
    if (item.links && item.links.length > 0) {
      result.push(item);
    }
    if (item.subItems) {
      item.subItems.forEach((subItem) => {
        if (subItem.links && subItem.links.length > 0) {
          result.push(subItem);
        }
      });
    }
    return result;
  }, []);
}

export { getNavigationItems, navigationItems };
